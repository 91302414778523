import React from "react"
import { handleGtag } from "../utils/gtag"
import { returnSelfUserId } from "../../lib/.auth"
import "../../styles/main.scss"
var userId = returnSelfUserId()
var footerItems = [
  { title: "Platform", link: "https://home.versi0n.io" },
  { title: "Privacy", link: "https://home.versi0n.io/privacy-policy.html" },
  { title: "Terms", link: "https://home.versi0n.io/terms.html" },
  { title: "Founder", link: "https://kuriko.vercel.app" },
]

export const Footer = ({ tabletMode, mobileMode }) => {
  return (
    <footer style={{ minWidth: "100%", paddingTop: "20px", marginTop: "10px" }}>
      <hr style={{ minWidth: "100%", margin: "0 0 5px 0" }} />

      <div className="container" style={mobileMode ? { padding: "20px" } : { display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 10px 50px" }}>
        <a href="https://home.versi0n.io" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "footer", userId)} aria-label="link">
          &copy; 2024-{new Date().getFullYear()} <span itemProp="name"> Version IO Sdn Bhd.</span>{(tabletMode || mobileMode) && <br />} All Rights Reserved
        </a>
        <div style={mobileMode ? { display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px" } : { display: undefined }}>
          {footerItems.map((item, i) => <a key={i} href={item.link} target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "footer", userId)} style={{ marginRight: "20px" }} aria-label="footer item">{item.title}</a>)}
        </div>
      </div>
    </footer >
  )
}