import { useState, Fragment } from "react"
import { Modal } from "./Elements"
import { handleGtag } from "../utils/gtag"
import { returnSelfUserId, logout } from "../../lib/.auth"
import "../../styles/main.scss"
import { FaGithub, FaLinkedin } from "react-icons/fa"
var userId = returnSelfUserId()
// var companyId = getItem("companyId")
const handleLogout = e => { logout(); window.scrollTo({ top: 0, behavior: 'smooth' }); handleGtag(e, "header", userId) }


export const Header = () => {
  const [displayModal, setDisplayModal] = useState(false)

  return (
    <Fragment>
      <nav className="navbar container" style={{ display: "flex", padding: "0 20px" }}>
        <div className="navbar-start" style={{ display: "flex" }}>
          <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/" onClick={e => handleGtag(e, "header", userId)}>
            <img
              src="https://res.cloudinary.com/dfeirxlea/image/upload/t_icon-crop-w-360-h-150/v1728219760/pj_m_home/j80bjzkwuvtethz1dybf.png"
              alt="Home" height="40" width="100%" style={{ minHeight: "40px" }}
            />
          </a>
          <div className="navbar-item has-dropdown is-hoverable">
            <p className="navbar-link">About Us</p>
            <div className="navbar-dropdown">
              <a aria-label="navbar item" className="navbar-item" href="https://home.versi0n.io" target="blank" rel="noreferrer" onClick={e => handleGtag(e, "header", userId)}>
                Platform
              </a>
              <a aria-label="navbar item" className="navbar-item" href="https://kuriko.vercel.app" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", userId)}>
                Founder <span style={{ display: "none" }} itemProp="founder">Kuriko I.</span>
              </a>
              <a aria-label="navbar item" className="navbar-item" href="/contact-us" onClick={e => handleGtag(e, "header", userId)}>
                Contact
              </a>
            </div>
          </div>
        </div>

        <div className="navbar-end" style={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
          <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://github.com/versionHQ/multi-agent-system" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", userId)}>
            <FaGithub style={{ height: "100px" }} />
          </a>
          <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://www.linkedin.com/in/k-i-i/" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", userId)}>
            <FaLinkedin style={{ height: "100px" }} />
          </a>

          {/* <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }}
            href={userId && companyId ? `/companies/${companyId}` : "/sign-up"}
            onClick={e => handleGtag(e, "header", userId)} disabled
          >
            Sign Up
          </a> */}
          {userId &&
            <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/" onClick={e => handleLogout(e)}>
              Logout
            </a>
          }
          {!userId &&
            <button
              className="button is-dark" style={{ display: "flex", alignItems: "center", height: "80%", color: "#fafafa", marginLeft: "10px" }}
              onClick={e => { setDisplayModal(!displayModal); handleGtag(e, "header", userId) }}>
              Schedule Demo
            </button>
          }
        </div>
      </nav>
      <Modal displayModal={displayModal} setDisplayModal={setDisplayModal} />
    </Fragment>
  )
}



export const MobileHeader = () => {
  const [isOpenBurger, setIsOpenBurger] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)

  return (
    <Fragment>
      <nav className="navbar">
        <div className="navbar-brand">
          <div className="navbar-start" style={{ display: "flex" }}>
            <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/" onClick={e => handleGtag(e, "header", userId)}>
              <img
                src="https://res.cloudinary.com/dfeirxlea/image/upload/t_icon-crop-w-360-h-150/v1728219760/pj_m_home/j80bjzkwuvtethz1dybf.png"
                alt="Home" height="40" width="100%" style={{ minHeight: "40px" }}
              />
            </a>
          </div>

          <div className={isOpenBurger ? "navbar-burger is-active" : "navbar-burger"} onClick={() => setIsOpenBurger(!isOpenBurger)} style={{ color: "#191b1b" }}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>

        {isOpenBurger &&
          <div className="navbar-dropdown" id="burger-menu">

            <a aria-label="navbar item" className="navbar-item" href="https://home.versi0n.io" target="blank" rel="noreferrer" onClick={e => handleGtag(e, "header", userId)}>
              About
            </a>
            <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://github.com/versionHQ/multi-agent-system" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", userId)}>
              Contribute <FaGithub style={{ height: "20px" }} />
            </a>
            <a aria-label="navbar item" className="navbar-item" href="https://kuriko.vercel.app" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", userId)}>
              Founder  <span style={{ display: "none" }} itemProp="founder">Kuriko I.</span>
            </a>
            <a aria-label="navbar item" className="navbar-item" href="/contact-us" onClick={e => handleGtag(e, "header", userId)}>
              Contact
            </a>

            <hr style={{ margin: "10px 0" }} />

            {userId &&
              <a aria-label="navbar item" className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/" onClick={e => handleLogout(e)}>
                Logout
              </a>
            }
            {!userId &&
              <button
                className="navbar-item" style={{ display: "flex", alignItems: "center", height: "80%", color: "#191b1b" }}
                onClick={e => { setDisplayModal(!displayModal); handleGtag(e, "header", userId) }}>
                Schedule Demo
              </button>
            }
          </div>
        }
      </nav>
      <Modal displayModal={displayModal} setDisplayModal={setDisplayModal} />
    </Fragment>
  )
}