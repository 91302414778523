import axios from "axios"
import { getToken } from "./.auth"
// axios.defaults.xsrfCookieName = "csrftoken" //not using session auth anymore
// axios.defaults.xsrfHeaderName = "X-CSRFToken"
export const baseUrl = "/api"
export const withHeaders = () => { return { headers: { Authorization: `Token ${getToken()}` } } }
export const source = axios.CancelToken.source()


// auth & user
export const registerUser = formData => { return axios.post(`/api/sign-up/`, formData) }
export const loginUser = formData => { return axios.post(`${baseUrl}/login/`, formData) }
export const verifyEmail = (userId, formData = {}) => {
  return axios.put(`${baseUrl}/verify_email/${Number(userId)}/`, formData, withHeaders())
}
export const changePassword = (userId, formData) => {
  return axios.put(`${baseUrl}/change_password/${Number(userId)}/`, formData, withHeaders())
}
export const fetchLinkedInProfile = formData => { return axios.post(`${baseUrl}/fetch_linkedin_profile/`, formData) }
export const editProfile = (userId, data) => { return axios.put(`${baseUrl}/get_or_edit/${Nunber(userId)}/`, data, withHeaders()) }


// company & prod
export const handleCompany = (companyId, formData = {}) => {
  return axios.get(`${baseUrl}/handle-company/${companyId}/`, withHeaders())
}
export const handleProduct = (companyId, productId, formData = {}) => {
  return Object.keys(formData).length > 0
    ? axios.put(`${baseUrl}/handle-product/${companyId}/${productId}/`, formData, withHeaders())
    : axios.get(`${baseUrl}/handle-product/${companyId}/${productId}/`, withHeaders())
}


// send email
export const sendEmail = formData => { return axios.post(`${baseUrl}/core/send-email/`, formData) }


// fetch third party url
export const fetchUrl = url => { return axios.post(`${baseUrl}/core/fetch-landing-page-via-url/`, { url: url }) }


// AI - text - image (ai + cloudinary)
export const draftInstruction = formData => { return axios.post(`${baseUrl}/draft-instruction/`, formData) }
export const generateTextVariations = formData => { return axios.post(`${baseUrl}/gen-texts/`, formData) }
export const predictPerformance = formData => { return axios.post(`${baseUrl}/predict-performance/`, formData) }
export const genareteImage = formData => { return axios.post(`${baseUrl}/gen-image/`, formData) }
export const transformImage = formData => { return axios.post(`${baseUrl}/transform-image/`, formData) }


// content
export const createContent = (companyUuid, formData) => { return axios.post(`${baseUrl}/create-content/${companyUuid}/`, formData, withHeaders()) }
export const getContent = companyUuid => { return axios.post(`${baseUrl}/get-content/${companyUuid}/`, withHeaders()) }



// facebook (auth, ad, insights)
// https://developers.facebook.com/docs/marketing-apis/overview/authentication/
// https://developers.facebook.com/docs/marketing-api/guides/lead-ads/create
const facebookBaseUrl = "https://graph.facebook.com/v20.0"
const headers = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" }
export const createFacebookAd = (adAccountId, type, formData = null) => {
  return axios.post(`${facebookBaseUrl}/act_${adAccountId}/${type}`, formData, { headers })
} // type = campaigns, adsets, ads
export const getFacebookInsights = (adAccountId, accessToken) => {
  return axios.get(`${facebookBaseUrl}/act_${adAccountId}/insights?access_token=${accessToken}`, { headers })
}
export const retrieveFacebookAccessToken = (companyId, clientId, clientSecret, fbAuthCode) => {
  const redirectUrl = `https://versi0n.io/companies/${companyId}/fb-auth/`
  return axios.post(`https://www.facebook.com/v20.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUrl}&scope=ads_management&scope=pages_show_list&scope=ads_read&scope=business_management&scope=pages_read_engagement&scope=pages_manage_ads`, { headers })
}