import { useState, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { TypeAnimation } from "react-type-animation"
import { ModelCard, models } from "./Model"
import { SignUp } from "./auth/Auth"
import { BackToPrevPage, Modal } from "./common/Elements"
import { generateTextVariations, predictPerformance, fetchUrl, draftInstruction } from "../lib/.api"
import { handleGtag } from "./utils/gtag"
import { returnSelfUserId } from "../lib/.auth"
import "../styles/main.scss"
import { FaInfoCircle } from "react-icons/fa"
import { Instruction } from "./Instruction"
var userId = returnSelfUserId()
var demoUrl = "https://business.linkedin.com/"
// var companyId = getItem("companyId")

//! UPDATEME - add a credit per day, for retention - start with dummy contact info, add improve x2 - chat with user

export const Playground = ({ tabletMode, mobileMode }) => {
  var navigate = useNavigate()
  var location = useLocation()
  const { model, title, subTitle } = location.state
  const goal = subTitle === "Custom" ? title.toLowerCase() : `${title} through ${subTitle}`.toLowerCase()


  // // handle daily quota: 3
  // let quota = useRef(0)
  // console.log(window.localStorage)


  // handle cta
  const [complete, setComplete] = useState(false)
  const [displayAuthModal, setDisplayAuthModal] = useState(false)
  const [displayAccountConnectionModal, setDisplayAccountConnectionModal] = useState(false)


  // draft instruction from landing url
  let fetchedUrl = useRef(demoUrl)
  const [isFetching, setIsFetching] = useState(false)
  const [isDrafting, setIsDrafting] = useState(false)
  const [error, setError] = useState(false)
  const [product, setProduct] = useState(null)
  const [audience, setAudience] = useState(null)
  const [usp, setUsp] = useState(null)
  const [instructionEditMode, setInstructionEditMode] = useState(false)
  // const [instruction, setInstruction] = useState(null)
  const handleFetchProductInfo = e => {
    e.preventDefault(); setIsFetching(true); handleGtag(e, "add url", userId); setError(false);
    fetchUrl(fetchedUrl.current)
      .then(res => {
        if (res.status !== 200) { setIsFetching(false); setError(true) }
        else if (res.data?.content && res.status === 200) {
          setIsFetching(false); setIsDrafting(true);
          const html = res.data.content

          draftInstruction({ html, goal, item: "product overview" })
            .then(res => {
              if (res.status !== 200) { setIsDrafting(false); setError(true) }
              else { setProduct(res.data?.data) }
            })
            .catch(err => { setIsDrafting(false); setError(true) })

          draftInstruction({ html: res, goal, item: "target audience" })
            .then(res => {
              if (res.status !== 200) { setIsDrafting(false); setError(true); return html }
              else { setAudience(res.data?.data); return html }
            })
            .catch(err => { setIsDrafting(false); setError(true) })


          draftInstruction({ html: res, goal, item: "unique selling poitns to the audience" })
            .then(res => {
              if (res.status !== 200) { setIsDrafting(false); setError(true) }
              else { setUsp(res.data?.data); }
            })
            .catch(err => { setIsDrafting(false); setError(true) })

          setIsDrafting(false);
        }

        else { setIsFetching(false); setError(true) }
      })
      .catch(err => { setIsFetching(false); setIsDrafting(false); setError(true) })
  }


  // generate variations and simurate performance
  const [isGenerating, setIsGenerating] = useState(false)
  const [isSimulating, setIsSimulating] = useState(false)
  const [textError, setTextError] = useState(false)
  const [textVariations, setTextVariations] = useState([]) // { text: "text1", scores: [a, b, c]}
  const handleTextVariation = e => {
    e.preventDefault(); handleGtag(e, "gen variation", userId); setIsGenerating(true); setComplete(false); setTextError(false)
    generateTextVariations({ product, audience, usp, goal })
      .then(res => {
        if (res.status !== 200) { setTextError(true); setIsGenerating(false); setComplete(false); return }
        else {
          setTextVariations(res.data.variations); setIsGenerating(false); setComplete(true); return res.data.variations
        }
      })
      .then(res => {
        setIsSimulating(true); setComplete(false)
        predictPerformance({ textVariations: res, product, audience, goal })
          .then(res => {
            if (res.status !== 200) { setIsSimulating(false); setTextError(false); setComplete(false) }
            else { setTextVariations([...res.data?.texts_with_score]); setIsSimulating(false); setTextError(false); setComplete(true) }
          })
          .catch(err => { setIsSimulating(false); setTextError(false) })
      })
      .catch(err => { setTextError(true); setIsGenerating(false); setIsSimulating(false); setComplete(false) })
  }
  console.log(textVariations)


  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href="https://beta.versi0n.io/playground" />
        <title>Playground (Beta) | version</title>
      </Helmet>

      <hr style={{ margin: "5px 0 0", minWidth: "100%", border: "0.3px solid #fafafa", height: "0.5px" }} />

      <div style={{ minWidth: "100%", display: "flex", flexDirection: mobileMode ? "column" : "row" }}>

        <div className="has-background-white-ter" style={{ minHeight: mobileMode ? "100px" : "100vh", padding: tabletMode ? "10px" : "10px 30px", width: mobileMode ? "100vw" : tabletMode ? "240px" : "400px", maxWidth: mobileMode ? "100vw" : tabletMode ? "240px" : "400px", minWidth: mobileMode ? "100vw" : tabletMode ? "240px" : "400px" }}>
          <BackToPrevPage style={{ fontSize: "12px", color: "#414141" }} pageName={"home"} />

          <div style={{ padding: mobileMode ? "5px" : "10px", fontSize: mobileMode ? "12px" : undefined }}>
            <p>Goal:</p>
            <p>{title}&nbsp;{">"}&nbsp;{subTitle}</p>
          </div>

          <div style={{ padding: mobileMode ? "5px" : "10px", fontSize: mobileMode ? "12px" : undefined }}>
            <p>Model:</p>
            <ModelCard
              {...models[model]}
              tabletMode={tabletMode}
              mobileMode={mobileMode}
              style={{ display: "flex", padding: tabletMode ? "5px" : "10px", }}
            />
          </div>
        </div>


        <div className="container" style={{ width: mobileMode ? "100%" : tabletMode ? "500px" : "1200px", minWidth: mobileMode ? "100%" : tabletMode ? "500px" : "1200px", maxWidth: mobileMode ? "100%" : tabletMode ? "500px" : "1200px", minHeight: mobileMode ? "100vh" : undefined }}>

          <div className="container" style={{ padding: mobileMode ? "30px 10px 10px" : "30px 10px", width: (mobileMode || tabletMode) ? "100%" : "800px" }}>
            <div style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
              <p style={{ width: "240px", display: (mobileMode || tabletMode) ? "none" : undefined }}>Landing URL</p>
              <input
                className="input" type="url" name="url" placeholder={demoUrl} style={{ marginRight: "10px" }}
                onChange={e => fetchedUrl.current = e.target.value}
              />
              <button className={isFetching ? "button is-loading" : "button"} onClick={e => handleFetchProductInfo(e)} style={{ width: "150px" }} disabled={instructionEditMode} aria-label="button">
                Draft instruction
              </button>
            </div>
            <p style={{ color: "#414141" }}>
              {isFetching ? "...reading url..." : isDrafting ? "...generating..." : undefined}
            </p>
            {error &&
              <button style={{ color: "#414141", fontSize: "12px", textDecoration: "underline" }} onClick={e => { setInstructionEditMode(true); setProduct('Add product overview, target audience, and selling points.'); handleGtag(e, "demo fetch error", userId) }} aria-label="button">
                Something went wrong. Input manually
              </button>
            }
          </div>


          <div className="container" style={{ padding: mobileMode ? "10px" : "30px 10px", width: (mobileMode || tabletMode) ? "100%" : "800px" }}>

            {instructionEditMode
              ?
              <div>
                <div style={{ display: "flex", margin: "16px 0", justifyContent: "space-between", flexDirection: (mobileMode || tabletMode) ? "column" : undefined }}>
                  <p style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Product overview:</p>
                  <textarea
                    className="textarea"
                    placeholder={product}
                    row={2}
                    style={{
                      fontSize: "14px", padding: "10px", minHeight: "100px", border: "solid 1px #dedede", borderRadius: "10px", display: "block", wordBreak: "auto-phrase", maxWidth: (mobileMode || tabletMode) ? "100%" : "650px", minWidth: (mobileMode || tabletMode) ? "100%" : "650px"
                    }}
                    onChange={e => { e.preventDefault(); setProduct(e.target.value) }}
                    aria-label="product"
                  />
                </div>

                <div style={{ display: "flex", margin: "16px 0", justifyContent: "space-between", flexDirection: (mobileMode || tabletMode) ? "column" : undefined }}>
                  <p style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Audience:</p>
                  <textarea
                    className="textarea"
                    placeholder={audience}
                    row={2}
                    style={{
                      fontSize: "14px", padding: "10px", minHeight: "100px", border: "solid 1px #dedede", borderRadius: "10px", display: "block", wordBreak: "auto-phrase", maxWidth: (mobileMode || tabletMode) ? "100%" : "650px", minWidth: (mobileMode || tabletMode) ? "100%" : "650px"
                    }}
                    onChange={e => { e.preventDefault(); setAudience(e.target.value) }}
                    aria-label="audience"
                  />
                </div>
                <div style={{ display: "flex", margin: "16px 0", justifyContent: "space-between", flexDirection: (mobileMode || tabletMode) ? "column" : undefined }}>
                  <p style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Selling points:</p>
                  <textarea
                    className="textarea"
                    placeholder={usp}
                    row={2}
                    style={{
                      fontSize: "14px", padding: "10px", minHeight: "100px", border: "solid 1px #dedede", borderRadius: "10px", display: "block", wordBreak: "auto-phrase", maxWidth: (mobileMode || tabletMode) ? "100%" : "650px", minWidth: (mobileMode || tabletMode) ? "100%" : "650px"
                    }}
                    onChange={e => { e.preventDefault(); setUsp(e.target.value) }}
                    aria-label="usp"
                  />
                </div>

                <button className="button is-primary" onClick={e => handleTextVariation(e)} style={{ marginTop: "10px" }} disabled={complete} aria-label="button">
                  Confirm
                </button>
              </div>
              :
              <div>
                {product &&
                  <div style={{ display: "flex", margin: "16px 0", justifyContent: "space-between", flexDirection: (mobileMode || tabletMode) ? "column" : undefined }}>
                    <p style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Product:</p>
                    <TypeAnimation
                      sequence={[product, 1000]}
                      speed={100}
                      style={{
                        fontSize: "14px", padding: "10px", minHeight: "100px", border: "solid 1px #dedede", borderRadius: "10px", display: "block", wordBreak: "auto-phrase", width: mobileMode ? "100%" : tabletMode ? "480px" : "660px", maxWidth: mobileMode ? "100%" : tabletMode ? "480px" : "660px", minWidth: mobileMode ? "100%" : tabletMode ? "480px" : "660px"
                      }}
                      repeat={3}
                    />
                  </div>
                }

                {usp &&
                  <div style={{ display: "flex", margin: "16px 0", justifyContent: "space-between", flexDirection: (mobileMode || tabletMode) ? "column" : undefined }}>
                    <p style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Selling points:</p>
                    <TypeAnimation
                      sequence={[usp, 1000]}
                      speed={100}
                      style={{
                        fontSize: "14px", padding: "10px", minHeight: "100px", border: "solid 1px #dedede", borderRadius: "10px", display: "block", wordBreak: "auto-phrase", width: mobileMode ? "100%" : tabletMode ? "480px" : "660px", maxWidth: mobileMode ? "100%" : tabletMode ? "480px" : "660px", minWidth: mobileMode ? "100%" : tabletMode ? "480px" : "660px"
                      }}
                      repeat={3}
                    />
                  </div>
                }

                {audience &&
                  <div style={{ display: "flex", margin: "16px 0", justifyContent: "space-between", flexDirection: (mobileMode || tabletMode) ? "column" : undefined }}>
                    <p style={{ fontSize: "14px", minWidth: "120px", maxWidth: "120px" }}>Audience:</p>
                    <TypeAnimation
                      sequence={[audience, 1000]}
                      speed={100}
                      style={{
                        fontSize: "14px", padding: "10px", minHeight: "100px", border: "solid 1px #dedede", borderRadius: "10px", display: "block", wordBreak: "auto-phrase", width: mobileMode ? "100%" : tabletMode ? "480px" : "660px", maxWidth: mobileMode ? "100%" : tabletMode ? "480px" : "660px", minWidth: mobileMode ? "100%" : tabletMode ? "480px" : "660px"
                      }}
                      repeat={3}
                    />
                  </div>
                }

                {(usp && product && audience) &&
                  <div className="buttons" style={{ marginTop: "10px" }}>
                    <button className="button" onClick={() => setInstructionEditMode(true)} disabled={complete} aria-label="button">
                      Edit
                    </button>
                    <button className="button is-primary" onClick={e => handleTextVariation(e)} disabled={complete} aria-label="button">
                      Confirm
                    </button>
                  </div>
                }
              </div>
            }
          </div>


          <div className="container" style={{ padding: mobileMode ? "10px" : "30px 10px", width: (mobileMode || tabletMode) ? "100%" : "800px" }}>
            <p style={{ color: "#414141" }}>
              {isGenerating ? "...generating variations..." : isSimulating ? "...predicting performance..." : undefined}
            </p>
            {textError &&
              <button style={{ color: "#414141", fontSize: "12px", textDecoration: "underline" }} aria-label="jump to the link" onClick={e => { navigate(-1); handleGtag(e, "demo text variation error", userId) }}>
                Something went wrong. Try again or try with another model
              </button>
            }

            {textVariations.length > 0 &&
              <div>
                <div className="container" style={{ height: "40px", width: mobileMode ? "100%" : tabletMode ? "480px" : "700px", display: mobileMode ? "none" : "flex", alignItems: "center", justifyContent: mobileMode ? "center" : "flex-end" }}>
                  <p style={{ fontSize: "12px", width: "50px", margin: "0 5px", textAlign: "center" }}>Overall score</p>
                  <p style={{ fontSize: "12px", width: "50px", margin: "0 5px", textAlign: "center" }}>Brand tone</p>
                  <p style={{ fontSize: "12px", width: "50px", margin: "0 5px", textAlign: "center" }}>Fit to audience</p>
                  <p style={{ fontSize: "12px", width: "50px", margin: "0 5px", textAlign: "center" }}>Factual correctness</p>
                </div>

                {textVariations.map((item, i) => {
                  const { text, scores } = item
                  return (
                    <div key={i} className="container" style={{ margin: "5px 0", padding: "5px", width: mobileMode ? "100%" : tabletMode ? "480px" : "720px", maxWidth: mobileMode ? "100%" : tabletMode ? "480px" : "720px", minWidth: mobileMode ? "100%" : tabletMode ? "480px" : "720px", display: "flex", flexDirection: mobileMode ? "column" : "row", alignItems: "center", height: mobileMode ? undefined : tabletMode ? "150px" : "100px" }}>
                      <TypeAnimation
                        sequence={[
                          text,
                          1000
                        ]}
                        speed={100}
                        style={{
                          fontSize: "14px", fontStyle: "italic", padding: "5px", display: "block", wordBreak: "auto-phrase",
                          minHeight: "80px", maxHeight: mobileMode ? "200px" : "80px", height: mobileMode ? undefined : "80px", width: mobileMode ? "100%" : tabletMode ? "240px" : "500px", minWidth: mobileMode ? "100%" : tabletMode ? "240px" : "500px", maxWidth: mobileMode ? "100%" : tabletMode ? "240px" : "500px"
                        }}
                        repeat={3}
                      />
                      <div style={{ minHeight: mobileMode ? "50px" : undefined, height: mobileMode ? "50px" : "100px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {mobileMode && <p>P-score&nbsp;<FaInfoCircle /></p>}
                        {scores?.map((v, int) => {
                          return (
                            <div key={int} style={{ backgroundColor: int === 0 ? "#00d1b2" : undefined, width: "30px", height: "30px", borderRadius: "30px", border: "1px solid #00d1b2", margin: "10px", display: "flex", justifyContent: "center", alignItems: "center", color: "#191b1b" }}>
                              {v}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            }
          </div>


          {(textVariations?.length > 0 && textVariations.find(item => item.scores.length > 0)) &&
            <Instruction
              tabletMode={tabletMode} mobileMode={mobileMode} prevResult={textVariations[0]?.text} product={product} usp={usp} audience={audience} setDisplayAuthModal={setDisplayAuthModal} setDisplayAccountConnectionModal={setDisplayAccountConnectionModal}
            />
          }
        </div >

      </div>
      <Modal
        displayModal={displayAuthModal} setDisplayModal={setDisplayAuthModal}
        title="Sign up and save"
        children={<SignUp hasHelmet={false} />}
        width={mobileMode ? "360px" : "500px"}
      />
      <Modal //! UPDATEME - modal to connect 3rd party account
        displayModal={displayAccountConnectionModal} setDisplayModal={setDisplayAccountConnectionModal}
        title="Connect your account"
        children={<div><p>Hubspot</p><p>Facebook</p><p>Salesforce</p></div>}
        width={mobileMode ? "360px" : "500px"}
      />

    </HelmetProvider >
  )
}