import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { handleGtag } from "../utils/gtag"
import { registerUser, loginUser } from "../../lib/.api"
import { setAuthItems } from "../../lib/.auth"
import "../../styles/main.scss"


export const SignUp = ({ hasHelmet = true }) => {
  const navigate = useNavigate()
  let key = useRef(""); let email = useRef(""); let companyName = useRef(""); let password = useRef(""); let confirmedPassword = useRef("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const handleSubmit = e => {
    e.preventDefault(); setIsSubmitting(true); handleGtag(e, "Sign Up")
    const formData = {
      key: key.current,
      email: email.current, companyName: companyName.current, password: password.current, password_confirmed: confirmedPassword.current
    }
    registerUser(formData)
      .then(res => {
        const user = res.data
        if (user.companyId === undefined) setError("Something went wrong.")
        else {
          setAuthItems(user.token, user.id, user.username, user.companyId)
          setIsSubmitting(false)
          navigate(`/companies/${user.companyId}`)
        }
      })
      .catch(err => { setIsSubmitting(false); setError(err.response.data.detail) })
  }

  return (
    <HelmetProvider>
      {hasHelmet &&
        <Helmet>
          <link rel="canonical" href="https://versi0n.io/sign-up" />
          <title>Sign Up | version</title>
        </Helmet>
      }

      <div className="container" style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
        <div className="card" style={{ maxWidth: "400px", minWidth: "400px", minHeight: "400px", padding: "10px", margin: "50px" }}>

          <div className="container" style={{ margin: "30px 10px" }}>
            <p className="label">Sign up</p>
            <p style={{ fontSize: "14px" }}>Already have an account? <a href="/sign-in" aria-label="sign in" style={{ textDecoration: "underline" }}>Sign in</a></p>
          </div>

          {/* <div className="container" style={{ margin: "10px" }}>
            <LinkedInAuthForm />
            <fieldset><legend>or</legend></fieldset>
          </div> */}

          <div className="container" style={{ marginTop: "10px" }}>
            <div className="control" style={{ margin: "10px" }}>
              <input
                className="input" type="email" placeholder="Work email"
                onChange={e => { e.preventDefault(); email.current = e.target.value }}
              />
            </div>
            <div className="control" style={{ margin: "10px" }}>
              <input
                className="input" type="text" placeholder="Company name"
                onChange={e => { e.preventDefault(); companyName.current = e.target.value }}
              />
            </div>

            <div className="control" style={{ margin: "10px" }}>
              <input
                className="input" type="password" placeholder="Password (min. 9)"
                onChange={e => { e.preventDefault(); password.current = e.target.value }}
              />
            </div>
            <div className="control" style={{ margin: "10px" }}>
              <input
                className="input" type="password" placeholder="Confirm password"
                onChange={e => { e.preventDefault(); confirmedPassword.current = e.target.value }}
              />
            </div>
          </div>

          <div className="container" style={{ margin: "10px" }}>
            <button
              className={isSubmitting ? "button is-dark is-loading" : "button is-dark"}
              style={{ marginRight: "10px", width: "100px", minWidth: "100px", maxWidth: "100px" }}
              onClick={e => handleSubmit(e)}
            >
              Submit
            </button>
          </div>

          <div className="container" style={{ margin: "10px" }}>
            {error && <p style={{ fontSize: "12px", color: "red" }}>{error} If this insists, contact us.</p>}
            <p style={{ fontSize: "8px" }}>By clicking the submit button, I agree with the terms of use and privacy policy.</p>
          </div>

        </div>
      </div>
    </HelmetProvider >
  )
}


export const SignIn = () => {
  const navigate = useNavigate()
  let email = useRef(""); let password = useRef("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const handleSubmit = e => {
    e.preventDefault(); setIsSubmitting(true); handleGtag(e, "Sign In")
    const formData = { email: email.current, password: password.current }
    loginUser(formData)
      .then(res => {
        const user = res.data
        setAuthItems(user.token, user.id, user.username, user.companyId)
        navigate(`/companies/${user.companyId}`)
      })
      .catch(err => { setIsSubmitting(false); setError("Something went wrong.") })
  }

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href="https://versi0n.io/sign-in" />
        <title>Sign In | version</title>
      </Helmet>

      <div className="container" style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
        <div className="card" style={{ maxWidth: "400px", minWidth: "400px", minHeight: "400px", padding: "10px", margin: "50px" }}>

          <div className="container" style={{ margin: "30px 10px" }}>
            <p className="label">Sign in</p>
            <p style={{ fontSize: "14px" }}>New to the site? <a href="/sign-up">Sign up</a></p>
          </div>

          {/* <div className="container" style={{ margin: "10px" }}>
            <LinkedInAuthForm />
            <fieldset><legend>or</legend></fieldset>
          </div> */}

          <div className="container" style={{ marginTop: "10px" }}>
            <div className="control" style={{ margin: "10px" }}>
              <input
                className="input" type="email" placeholder="Work email"
                onChange={e => { e.preventDefault(); email.current = e.target.value }}
              />
            </div>
            <div className="control" style={{ margin: "10px" }}>
              <input
                className="input" type="password" placeholder="Password"
                onChange={e => { e.preventDefault(); password.current = e.target.value }}
              />
            </div>
          </div>


          <div className="container" style={{ margin: "10px" }}>
            <button
              className={isSubmitting ? "button is-dark is-loading" : "button is-dark"}
              style={{ marginRight: "10px", width: "100px", minWidth: "100px", maxWidth: "100px" }}
              onClick={e => handleSubmit(e)}
            >
              Submit
            </button>
          </div>

          <div className="container" style={{ margin: "10px" }}>
            {error && <p style={{ fontSize: "10px", color: "red" }}>{error} If this persists, contact us.</p>}
          </div>

        </div>
      </div>
    </HelmetProvider >
  )
}
