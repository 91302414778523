import React from "react"
import { createRoot } from "react-dom/client"
import { App } from "./App"
import "../src/styles/main.scss"

// const path = window.location.pathname
// const componentToRender = path === "/playground" ? "playground" : "workspace"
const root = createRoot(document.getElementById("root"))
var mobileMode = Boolean(window.innerWidth <= 430)
var tabletMode = Boolean(window.innerWidth <= 800 && window.innerWidth > 430)

root.render(<App mobileMode={mobileMode} tabletMode={tabletMode} />)

//! IMPROVEME - set different subdomain by device type.