import { useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { ModelIndex } from "./Model"
import { NewsBar, Modal } from "./common/Elements"
import { handleGtag } from "./utils/gtag"
import { returnSelfUserId } from "../lib/.auth"
import "../styles/main.scss"
var userId = returnSelfUserId()
var itemList = [
  {
    title: "Lead Generation",
    exp: "Attract qualified prospects on social media display ads",
    items: [
      { title: "Facebook ad", exp: "Draft competitive message for facebook ads", },
      // { title: "SEO", exp: "Launch a Google search keyword campaign with competitive messages", },
      { title: "Referral", exp: "Launch a referral campaign with competitive messages", },
      { title: "Exclusive offer", exp: "Highlight discount, coupon, other exclusive offers", },
      { title: "Custom", exp: "Build your own", }
    ]
  },
  {
    title: "Upsell",
    exp: "Predict customer's purchase intention and offer complementary products.",
    items: [
      { title: "Custom", exp: "Build your own", }
    ]
  },
  {
    title: "Churn Prevention",
    exp: "Assess churn risk of each customer and promote personalized offers.",
    items: [
      { title: "Custom", exp: "Build your own", }
    ]
  },
]



export const NewHome = ({ tabletMode, mobileMode }) => {
  const [selectedId, setSelectedId] = useState(null)
  const [selectedSubId, setSelectedSubId] = useState(null)
  const [clickedSubId, setClickedSubId] = useState(null)
  const [displayModelModal, setDisplayModelModal] = useState(false)

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href="https://beta.versi0n.io/" />
        <title>Home | version</title>
      </Helmet>

      <div className="content">
        <div className="container" style={{ maxHeight: (mobileMode || tabletMode) ? undefined : "840px", maxWidth: mobileMode ? "100%" : tabletMode ? "700px" : "1200px", padding: (mobileMode || tabletMode) ? "50px 20px" : "100px 50px", display: "flex", flexDirection: "column", alignItems: "center" }}>

          <NewsBar
            newsTitle={"Currently on the Accelerator Program with AWS"}
            link={"https://www.linkedin.com/in/k-i-i/"}
            fontSize={(mobileMode || tabletMode) ? "12px" : "16px"}
          />

          <h1 style={{ fontSize: (mobileMode || tabletMode) ? "36px" : undefined, lineHeight: 1.5, fontWeight: 700, padding: "0 0 10px 0" }} itemProp="description">
            Acquire <span style={{ background: "-webkit-linear-gradient(left, #00d1b2, #169d87)", backgroundClip: "text", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>loyal customers</span> with
            <br />
            hyper-personalized messaging
          </h1>

          <h2 style={{ lineHeight: 1.1, padding: "30px 0" }}>
            What would you like to tackle?
          </h2>

          {(mobileMode || tabletMode) &&
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              {itemList.map((item, i) => {
                const { title, exp, items } = item
                return (
                  <div key={i} style={{ dispaly: "flex", flexDirection: "column", alignItems: "center", margin: "20px 0" }}>
                    <button
                      className={selectedId === i ? "button is-primary" : "button is-primary is-outlined"} aria-label="button"
                      style={{ display: "block", margin: "15px auto", height: "60px", width: "240px", maxWidth: "240px", color: "#191b1b" }}
                      onClick={e => { setClickedSubId(null); setSelectedId(i); handleGtag(e, "home", userId) }}>
                      {title}
                    </button>
                    <p style={{ maxWidth: "100%", textAlign: "center", color: "#414141", fontSize: "12px" }}>
                      {exp}
                    </p>

                    {selectedId === i &&
                      <div style={{ display: "flex", marginTop: "10px", flexWrap: "wrap", justifyContent: "center" }}>
                        {items.map((item, i) => {
                          const { title, exp } = item
                          return (
                            <button
                              key={i} aria-label="button"
                              style={{ borderRadius: "10px", padding: "5px", maxWidth: "120px", minWidth: "120px", minHeight: "80px", maxHeight: "80px", margin: "3px", display: "flex", flexDirection: "column", textAlign: "left", border: i === selectedSubId ? "2px solid  #00d1b2" : ".5px solid #00d1b2", backgroundColor: i === clickedSubId ? "#00d1b2" : "#00000" }}
                              onMouseEnter={() => setSelectedSubId(i)}
                              onMouseLeave={() => setSelectedSubId(null)}
                              onClick={e => {
                                setClickedSubId(i); setDisplayModelModal(true); handleGtag(e, `home ${title}`, userId);
                              }}>
                              <p style={{ fontSize: "12px" }}>{title}</p>
                              <p style={{ fontSize: "10px", color: "#414141" }}>{exp}</p>
                            </button >
                          )
                        })}
                      </div>
                    }
                  </div>
                )
              })}
            </div>
          }

          {!(mobileMode || tabletMode) &&
            <div style={{ display: "flex", height: "200px" }}>
              {itemList.map((item, i) => {
                const { title, exp } = item
                return (
                  <div key={i} style={{ dispaly: "flex", flexDirection: "column" }}>
                    <button
                      className={selectedId === i ? "button is-primary" : "button is-primary is-outlined"} aria-label="button"
                      style={{ margin: "15px", display: "block", height: "60px", width: "240px", maxWidth: "240px", color: "#191b1b" }}
                      onClick={e => { setClickedSubId(null); setSelectedId(i); handleGtag(e, "home", userId) }}>
                      {title}
                    </button>
                    <p style={{ width: "240px", maxWidth: "240px", marginLeft: "20px", color: "#414141", fontSize: "12px" }}>
                      {exp}
                    </p>
                  </div>
                )
              })}
            </div>
          }

          {!(mobileMode || tabletMode) &&
            <div style={{ display: "flex", height: "200px", marginTop: "50px", width: "800px", flexWrap: "wrap" }}>
              {itemList[selectedId]?.items?.map((item, i) => {
                const { title, exp } = item
                return (
                  <button
                    key={i} aria-label="button"
                    style={{ borderRadius: "10px", padding: "15px 10px", maxWidth: "180px", minWidth: "180px", minHeight: "100px", maxHeight: "100px", margin: "10px", display: "flex", flexDirection: "column", textAlign: "left", border: i === selectedSubId ? "2px solid  #00d1b2" : ".5px solid #00d1b2", backgroundColor: i === clickedSubId ? "#00d1b2" : "#00000" }}
                    onMouseEnter={() => setSelectedSubId(i)}
                    onMouseLeave={() => setSelectedSubId(null)}
                    onClick={e => {
                      setClickedSubId(i); setDisplayModelModal(true); handleGtag(e, `home ${title}`, userId);
                    }}>
                    <p>{title}</p>
                    <p style={{ fontSize: "12px", color: "#414141" }}>{exp}</p>
                  </button >
                )
              })}
            </div>
          }
        </div>
      </div>

      <Modal
        displayModal={displayModelModal}
        setDisplayModal={setDisplayModelModal}
        title="Select a base model"
        children={<ModelIndex title={itemList[selectedId]?.title} subTitle={itemList[selectedId]?.items[clickedSubId]?.title} />}
        width={mobileMode ? "360px" : "500px"}
      />
    </HelmetProvider >
  )
}