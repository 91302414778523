import { Redirect } from "../utils/Redirect"
import "../../styles/loading.scss"
const minHeight = window.innerWidth > 1300 ? "1000px" : window.innerWidth <= 420 ? "600px" : "800px"



export const Message = ({ message = null, redirectTo = "/", ...props }) => {
  // access denied, not found, error, successfully completed
  if (redirectTo) Redirect(redirectTo, {}, 5000)

  return (
    <div className="container" style={{ minHeight }}>
      <div className="content" style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <h3>{message ? message : "Coming soon"}</h3>
      </div>
    </div>
  )
}


export const Loading = props => {
  const { statusMsg, style } = props

  return (
    <div className="container Loading" style={{ ...style }}>
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      <br />
      {statusMsg && <p >{statusMsg}</p>}
    </div >
  )
}