import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { TypeAnimation } from "react-type-animation"
import { FaArrowCircleUp } from "react-icons/fa"
import { handleGtag } from "./utils/gtag"
import { generateTextVariations } from "../lib/.api"
import { returnSelfUserId } from "../lib/.auth"
var userId = returnSelfUserId()



export const Instruction = ({ mobileMode, tabletMode, product = null, usp = null, audience = null, prevResult = null, placeholder = "Refine the message", ...props }) => {
  const navigate = useNavigate()
  const [instruction, setInstruction] = useState(null)
  const [isGenerating, setIsGenerating] = useState(false)
  const [complete, setComplete] = useState(false)
  const [textError, setTextError] = useState(false)
  const [answer, setAnswer] = useState(null)
  const handleTextVariation = e => {
    e.preventDefault(); handleGtag(e, "gen variation", userId); setIsGenerating(true); setComplete(false); setTextError(false)
    generateTextVariations({ instruction, product, audience, usp, prevResult })
      .then(res => {
        if (res.status !== 200) { setTextError(true); setIsGenerating(false); setComplete(false); return }
        else {
          setAnswer(res.data["text"]); setIsGenerating(false); setComplete(true);
        }
      })
      .catch(err => { setTextError(true); setIsGenerating(false); setComplete(false) })
  }

  return (
    <div className="container" style={{ padding: mobileMode ? "10px" : "30px 10px", width: (mobileMode || tabletMode) ? "100%" : "800px" }}>
      <textarea
        className="textarea"
        placeholder={placeholder}
        row={1}
        style={{
          fontSize: "14px", padding: "10px", minHeight: "50px", border: "solid 1px #dedede", borderRadius: "10px", display: "block", wordBreak: "auto-phrase"
        }}
        onChange={e => { e.preventDefault(); setInstruction(e.target.value) }}
      />
      <button aria-label="confirm" style={{ position: "relative", bottom: "35px", left: mobileMode ? "90%" : "95%" }} onClick={e => handleTextVariation(e)} disabled={complete}>
        <FaArrowCircleUp style={{ color: "#414141", fontSize: "20px" }} />
      </button>

      <p style={{ color: "#414141" }}>
        {isGenerating ? "...generating variations..." : undefined}
      </p>
      {textError &&
        <button style={{ color: "#414141", fontSize: "12px", textDecoration: "underline" }} aria-label="jump to the link" onClick={e => { navigate(-1); handleGtag(e, "demo text variation error", userId) }}>
          Something went wrong. Try again or try with another model
        </button>
      }

      {answer !== null &&
        <div className="container" style={{ margin: "5px 0", padding: "5px", width: mobileMode ? "100%" : tabletMode ? "480px" : "720px", maxWidth: mobileMode ? "100%" : tabletMode ? "480px" : "720px", minWidth: mobileMode ? "100%" : tabletMode ? "480px" : "720px", display: "flex", flexDirection: mobileMode ? "column" : "row", alignItems: "center", height: mobileMode ? undefined : tabletMode ? "150px" : "100px" }}>
          <TypeAnimation
            sequence={[
              answer,
              1000
            ]}
            speed={100}
            style={{
              fontSize: "14px", fontStyle: "italic", padding: "5px", display: "block", wordBreak: "auto-phrase",
              minHeight: "80px", maxHeight: mobileMode ? "200px" : "80px", height: mobileMode ? undefined : "80px", width: mobileMode ? "100%" : tabletMode ? "240px" : "500px", minWidth: mobileMode ? "100%" : tabletMode ? "240px" : "500px", maxWidth: mobileMode ? "100%" : tabletMode ? "240px" : "500px"
            }}
            repeat={3}
          />
        </div>
      }

      {answer !== null &&
        <div className="buttons">
          <button className="button" style={{ marginRight: "10px" }} onClick={e => { handleGtag(e, "save model", userId); props.setDisplayAuthModal(true) }} aria-label="save this model">
            Save this model
          </button>
          <button className="button" style={{ marginRight: "10px" }} onClick={e => { handleGtag(e, "ship content", userId); props.setDisplayAccountConnectionModal(true) }} aria-label="ship the content">
            Ship content
          </button>
        </div>
      }
    </div>
  )
}