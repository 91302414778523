import { useRef, useState, Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { Modal } from "./common/Elements"
import { handleGtag } from "./utils/gtag"
import { returnSelfUserId } from "../lib/.auth"
var userId = returnSelfUserId()
var agentList = [
  {
    name: "leo",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1728833313/pj_m_trial/b2h5tdqglf1i5cetw19v.png",
    exp:
      <>
        Smart, quick model specialized in friendly and playful messaging.
        <br />i.e.,<span style={{ fontStyle: "italic" }}>"Hi there, curious learner!"</span>
      </>
  },
  {
    name: "henry",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1728836216/pj_m_trial/uhkel3zbeqpfqp8ebsmt.png",
    exp:
      <>
        Smart, quick model specialized in thoughtful and elegant messaging.
        <br />i.e.,<span style={{ fontStyle: "italic" }}>"Discover a world of knowledge at Udemy."</span>
      </>
  },
  {
    name: "victor",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1728836216/pj_m_trial/bf5d1rqvpaj8c9xjupah.png",
    exp:
      <>
        Smart, quick model specialized in confident, practical, authoritative messaging.
        <br />i.e., <span style={{ fontStyle: "italic" }}>"Elevate your career with Udemy."</span>
      </>
  },
]
var modelList = [
  {
    name: "o1 Preview",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728655654/pj_m_trial/eycos2gscd8jwgymylqb.png",
    exp: "OpenAI's advanced reasoning model",
    isPro: true
  },
  {
    name: "o1 Mini",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728655654/pj_m_trial/eycos2gscd8jwgymylqb.png",
    exp: "OpenAI's efficient reasoning model",
    isPro: true
  },
  {
    name: "GPT-4o mini",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728825677/pj_m_trial/cxn9dgvztxbojz4npqyw.png",
    exp: "OpenAI's most efficient language model",
    isPro: false
  },
  {
    name: "GPT-4o",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728825677/pj_m_trial/cxn9dgvztxbojz4npqyw.png",
    exp: "OpenAI's most advanced language model",
    isPro: false
  },
  // {
  //   name: "GPT-4 Turbo",
  //   logo: "",
  //   exp: "OpenAI's legacy fast model",
  //   isPro: false
  // },
  // {
  //   name: "GPT-4",
  //   logo: "",
  //   exp: "OpenAI's legacy advanced model",
  //   isPro: false
  // },
  {
    name: "Claude 3.5 Sonnet",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827111/pj_m_trial/b8z04ne57pdnhedc9pb3.png",
    exp: "Anthropic's most advanced model",
    isPro: true
  },
  {
    name: "Claude 3 Sonnet",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827111/pj_m_trial/b8z04ne57pdnhedc9pb3.png",
    exp: "Anthropic's legacy efficient model",
    isPro: false
  },
  {
    name: "Claude 3 Opus",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827111/pj_m_trial/b8z04ne57pdnhedc9pb3.png",
    exp: "Anthropic's legacy advanced model",
    isPro: false
  },
  {
    name: "Claude 3 Haiku",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827111/pj_m_trial/b8z04ne57pdnhedc9pb3.png",
    exp: "Anthropic's fastest model",
    isPro: false
  },
  // {
  //   name: "Claude 2",
  //   logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827111/pj_m_trial/b8z04ne57pdnhedc9pb3.png",
  //   exp: "Anthropic's legacy model",
  //   isPro: false
  // },
  {
    name: "Llama 3.2 90B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827481/pj_m_trial/fqs3yr4rvc0agrjkk4u6.webp",
    exp: "Meta's most advanced model",
    isPro: true
  },
  {
    name: "Llama 3.2 11B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827481/pj_m_trial/fqs3yr4rvc0agrjkk4u6.webp",
    exp: "Meta's most efficient model",
    isPro: false
  },
  {
    name: "Llama 3.1 405B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827481/pj_m_trial/fqs3yr4rvc0agrjkk4u6.webp",
    exp: "Meta's legacy advanced model",
    isPro: false
  },
  {
    name: "Llama 3.1 70B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827481/pj_m_trial/fqs3yr4rvc0agrjkk4u6.webp",
    exp: "Meta's legacy efficient model",
    isPro: false
  },
  {
    name: "Llama 3",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827481/pj_m_trial/fqs3yr4rvc0agrjkk4u6.webp",
    exp: "Meta's legacy model",
    isPro: false
  },
  {
    name: "Mistral Large 2",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827861/pj_m_trial/qzlvoe5x2lvetx5htrf2.png",
    exp: "Mistral AI's most advanced model",
    isPro: false
  },
  {
    name: "Gemini 1.5 Pro",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827948/pj_m_trial/bafblalqufyhzp60ommd.png",
    exp: "Google's most advanced model",
    isPro: false
  },
  {
    name: "Gemini 1.5 Flash",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827948/pj_m_trial/bafblalqufyhzp60ommd.png",
    exp: "Google's latest and fastest model",
    isPro: false
  },
  {
    name: "Gemini 1.0 Pro",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728827948/pj_m_trial/bafblalqufyhzp60ommd.png",
    exp: "Google's legacy model",
    isPro: false
  },
  {
    name: "DBRX - Instruct",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728828013/pj_m_trial/qixrwzgqpsosiecp029d.png",
    exp: "Databricks' latest model",
    isPro: false
  },
  {
    name: "Command R",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728828155/pj_m_trial/wjvulzytqpprhed92loz.png",
    exp: "Cohere's fastest model",
    isPro: false
  },
  {
    name: "Command R+",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w50-h-auto/v1728828155/pj_m_trial/wjvulzytqpprhed92loz.png",
    exp: "Cohere's advanced model",
    isPro: false
  },
  // {
  //   name: "Solar 1 Mini",
  //   logo: "",
  //   exp: "Upstage's latest model",
  //   isPro: false
  // },
]
export var models = agentList.concat(modelList)


export const ModelCard = props => {
  const { name, logo, exp } = props
  return (
    <div style={{ ...props?.style }}>
      <img src={logo} alt={name.slice(0, 2)} width={50} height={50} style={{ borderRadius: "10px", maxHeight: "50px" }} />
      <div style={{ width: props?.mobileMode ? "100%" : props?.tabletMode ? "120px" : "280px", margin: props?.tabletMode ? "0 0 0 10px" : "0 10px 0 30px", textAlign: "left" }}>
        <p style={{ fontWeight: 700 }}>{name}</p>
        <p style={{ fontSize: props?.mobileMode ? "10px" : "13px" }}>{exp}</p>
      </div>
    </div>
  )
}


export const ModelIndex = props => {
  const navigate = useNavigate();
  let selectedModel = useRef(null);
  const [displayPricingModal, setDisplayPricingModal] = useState(false)
  const handleModelSelection = (e, isPro, i) => {
    if (isPro) { setDisplayPricingModal(true) }
    else {
      selectedModel.current = i
      navigate("/playground", { state: { model: i, title: props?.title, subTitle: props?.subTitle } })
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
    handleGtag(e, "model selection", userId)
  }

  return (
    <Fragment>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p style={{ fontSize: "18px" }}>Experience the advanced capabilities of our custom agents.</p>
        {agentList.map((item, i) => {
          const { name, logo, exp, isPro } = item
          return (
            <button key={i} style={{ display: "flex", borderBottom: "1px grey solid", padding: "10px" }} onClick={e => { handleModelSelection(e, isPro, i) }} aria-label="button">
              <img src={logo} alt={name.slice(0, 2)} width={50} height={50} style={{ borderRadius: "10px" }} />
              <div style={{ width: "280px", margin: "0 10px 0 30px", textAlign: "left" }}>
                <p style={{ fontWeight: 700 }}>{name}</p>
                <p style={{ fontSize: "13px" }}>{exp}</p>
              </div>
            </button>
          )
        })}

        <hr />

        <p style={{ fontSize: "18px" }}>Explore leading AI models, enhanced by version AI.</p>
        {modelList.map((item, i) => {
          const { name, logo, exp, isPro } = item
          return (
            <button key={i} style={{ display: "flex", borderBottom: "1px grey solid", padding: "10px" }} onClick={e => { handleModelSelection(e, isPro, i + 3) }} aria-label="button">
              <img src={logo} alt={name.slice(0, 2)} width={50} height={50} />
              <div style={{ width: "240px", margin: "0 10px 0 30px", textAlign: "left" }}>
                <p style={{ fontWeight: 700 }}>{name}</p>
                <p style={{ fontSize: "12px" }}>{exp}</p>
              </div>
              {isPro && <span className="tag is-primary">Pro</span>}
            </button>
          )
        })}
      </div>

      <Modal
        title="Pricing plan"
        displayModal={displayPricingModal}
        setDisplayModal={setDisplayPricingModal}
        children={<>Login with your paid account</>}
        width="300px"
      />
    </Fragment>
  )
}
