import { useRef, useState } from "react"
import { HelmetProvider, Helmet } from "react-helmet-async"
import { sendEmail } from "../../lib/.api"
import { handleGtag } from "../utils/gtag"
import { returnSelfUserId } from "../../lib/.auth"
var userId = returnSelfUserId()



export const ContactForm = ({ width = "700px", height = "300px", rows = 10, defaultMessage = null, ...props }) => {
  let name = useRef(""); let email = useRef(""); let message = useRef("")
  const [isSending, setIsSending] = useState(false); const [sent, setSent] = useState(false); const [error, setError] = useState(null)
  const sendMessage = e => {
    e.preventDefault(); setIsSending(true); handleGtag(e, "Contact us form submit", userId)
    sendEmail({ user_id: userId !== undefined ? userId : null, name: name.current, email: email.current, message: message.current ? message.current : defaultMessage })
      .then(res => {
        if (res.status === 200) { setSent(true); name.current = ""; email.current = ""; message.current = ""; setIsSending(false) }
        else setError("Something went wrong.")
      })
      .catch(err => { setIsSending(false); setError(err) })
  }

  return (
    <div className="container" style={{ width: width, maxWidth: width, minHeight: height, padding: "10px" }}>
      {sent
        ?
        <p style={{ textAlign: "center" }}>Successfully sent. We will get back to you shortly.</p>
        :
        <div>
          <div className="container" style={{ display: "flex" }}>
            <input
              className="input"
              type="text" placeholder="John" style={{ marginRight: "10px" }}
              onChange={e => name.current = e.target.value}
            />
            <input
              className="input"
              type="email" placeholder="john@work.com"
              onChange={e => email.current = e.target.value}
            />
          </div>

          <textarea
            className="textarea"
            rows={rows} placeholder="Hello world" style={{ margin: "10px 0" }} defaultValue={defaultMessage}
            onChange={e => message.current = e.target.value}
          />

          <button className={isSending ? "button is-dark is-loading" : "button is-dark"} onClick={e => sendMessage(e)} style={{ width: "120px" }}>
            Send
            {/* &nbsp;<MdSend /> */}
          </button>

          {error &&
            <p style={{ color: "red", fontSize: "12px" }}>
              Something went wrong. If this error insists, contact us.
            </p>
          }
        </div>
      }
    </div>
  )
}


export const ContactUs = ({ tabletMode, mobileMode }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href="https://versi0n.io/contact-us" />
        <title>Contact Us | version</title>
      </Helmet>

      <div className="container content" id="contact-us" style={{ minHeight: "700px", padding: mobileMode ? "10px" : tabletMode ? "50px 30px" : "120px 80px", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <h2>Contact us</h2>
        <p>Let us know your thought.</p>
        <ContactForm width={window.innerWidth <= 430 ? "100%" : "700px"} />
      </div>
    </HelmetProvider>
  )
}
